import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { createTheme, createColor } from '@manakin/theme';
import { beautySecondary, white, grey, mountain, black } from '../colors';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import loginBackgroundImage from '../../assets/login-background.png';
import accountBackgroundImage from '../../assets/account-background.jpg';
import logo from '../../assets/logo.png';
import pdfIcon from '../../assets/pdf.svg';
import loginVisual from '../../assets/login-visual.svg';
import footerLogo from '../../assets/footer-logo.svg';
import Cookies from 'js-cookie';

const yellow = createColor('#c9e7e9');
const green = createColor('#3e9618');

const _primary = Cookies.get('primary') || '#1868a3';
const _secondary = Cookies.get('secondary') || '#50bbb8';
const _tertiary = Cookies.get('tertiary') || '#e10e3e';
const _palette = Cookies.get('palette') || '#406692';

const theme = (obj = {}) => {
	const primaryButtonBackgroundColor = obj.primaryButtonBackgroundColor;
	const primaryButtonHoverBackgroundColor = obj.primaryButtonHoverBackgroundColor;
	const primaryButtonBorderColor = obj.primaryButtonBorderColor;
	const primaryButtonHoverBorderColor = obj.primaryButtonHoverBorderColor;
	const secondaryButtonBackgroundColor = obj.secondaryButtonBackgroundColor;
	const secondaryButtonHoverBackgroundColor = obj.secondaryButtonHoverBackgroundColor;
	const secondaryButtonBorderColor = obj.secondaryButtonBorderColor;
	const secondaryButtonHoverBorderColor = obj.secondaryButtonHoverBorderColor;
	const primaryFontColor = obj.primaryFontColor;
	const homePageBlockPrimaryBackgroundColor = obj.homePageBlockPrimaryBackgroundColor;
	const homePageBlockSecondaryBackgroundColor = obj.homePageBlockSecondaryBackgroundColor;

	let _obj = {};
	_obj.primaryButtonBackgroundColor = obj.primaryButtonBackgroundColor;
	_obj.primaryBackgroundColor = obj.primaryBackgroundColor;
	_obj._primary = _primary;
	_obj._secondary = obj.themeColor || _secondary;
	_obj._tertiary = _tertiary;
	_obj._palette = _palette;
	_obj.indication = obj.indicationColor || '#000';
	_obj._primaryWorkform = _primary;
	_obj._secondaryWorkform = _secondary;
	_obj.buttonRadius = obj.buttonBorderRadius || '0px';

	//dynamic colors
	_obj.defaultBox = obj.defaultBoxColor || _palette;
	_obj.defaultElement = obj.defaultWorkformColor || obj.defaultElementColor || _obj._secondary;
	_obj.primaryElement = obj.primaryElementColor || obj.primaryWorkformColor || _obj._secondary;
	_obj.secondaryElement = obj.defaultWorkformColor || obj.secondaryElementColor || _obj._primary;
	_obj.defaultWorkform = obj.defaultWorkformColor || _obj._secondary;
	_obj.primaryWorkform = obj.primaryWorkformColor || obj.primaryElementColor || _obj._primary;
	_obj.secondaryWorkform = obj.secondaryWorkformColor || obj.secondaryElementColor || _obj._secondaryWorkform;

	//Dashboard
	_obj.dashboardContentColor = createColor(_palette);
	_obj.dashboardTitleColor = createColor(primaryFontColor || _palette);
	_obj.dashboardResumeButton = createColor(obj.themeColor || _primary);
	_obj.dashboardBackgroundColor = createColor(_obj._secondary);

	///////COLORS
	_obj.createdPalette = createColor(_obj._palette);
	_obj.indicationColor = createColor(_obj.indication);

	//ELEMENT COLORS
	_obj.defaultElementColor = createColor(_obj.defaultElement);
	_obj.primaryElementColor = createColor(_obj.primaryElement);
	_obj.secondaryElementColor = createColor(_obj.secondaryElement);

	//WORKFORM COLORS
	_obj.defaultWorkformColor = createColor(_obj.defaultWorkform);
	_obj.primaryWorkformColor = createColor(_obj.primaryWorkform);
	_obj.secondaryWorkformColor = createColor(_obj.secondaryWorkform);

	//primary and secondary colors
	_obj.primaryColor = createColor(_obj._primary);
	_obj.secondaryColor = createColor(_obj._secondary);
	_obj.tertiaryColor = createColor(_obj._tertiary);
	_obj.mountainColor = createColor(mountain);

	//Palette colors (Used for buttons, icons, navigation enz)
	_obj.primaryPaletteColor = _obj.createdPalette;
	_obj.secondaryPaletteColor = white;

	//type colors
	_obj.typeColor = _obj._tertiary;
	_obj.primaryTypeColor = white;
	_obj.secondaryTypeColor = _obj.createdPalette;

	//box backgroundColors
	_obj.defaultBoxBGColor = _obj.createdPalette;
	_obj.boxBGColor = createColor(_obj.primaryBackgroundColor);

	//box name colors
	_obj.defaultBoxColor = createColor(_obj.defaultBox);
	_obj.primaryBoxColor = white;
	_obj.secondaryBoxColor = _obj.createdPalette;

	//element colors
	_obj.defaultElementBGColor = _obj.defaultElementColor;
	_obj.primaryElementBGColor = _obj.primaryElementColor;
	_obj.secondaryElementBGColor = _obj.secondaryElementColor;

	//backgroundColors
	_obj.defaultBackgroundColor = _obj.primaryColor;
	_obj.primaryFontColor = createColor(primaryFontColor || _obj._palette);
	_obj.defaultTitleColor = createColor(_obj._palette);
	_obj.defaultContentColor = createColor('#4E5666');
	_obj.secondaryBackgroundColor = grey;

	//Menu colors
	_obj.mainMenuBackground = _obj.primaryPaletteColor;
	_obj.mainMenuColor = beautySecondary;
	_obj.mainMenuSecondaryColor = white;

	//navigation colors
	_obj.navigationColor = black;
	_obj.secondaryNavigationColor = _obj.primaryPaletteColor;
	_obj.linkColor = _obj.primaryPaletteColor;
	_obj.defaultContentHover = _obj.secondaryColor;
	_obj.LessonButtonColor = createColor(obj.themeColor || _obj._palette);
	_obj.defaultBorderHoverColor = _obj.secondaryColor;

	//BUTTONS
	_obj.homeButtonBGColor = createColor(primaryButtonBackgroundColor || obj.themeColor || _obj._palette);
	_obj.homeButtonBGColorHover = createColor(primaryButtonHoverBackgroundColor || _obj.homeButtonBGColor[700]);
	_obj.homeButtonBorderColor = createColor(primaryButtonBorderColor || _obj.homeButtonBGColor[700]);
	_obj.homeButtonBorderColorHover = createColor(primaryButtonHoverBorderColor || _obj.homeButtonBGColorHover[700]);

	_obj.buttonBGColor = createColor(obj.themeColor || _obj._palette);
	_obj.buttonBGColorHover = createColor(_obj.buttonBGColor[700]);
	_obj.buttonBorderColor = createColor(_obj.buttonBGColor[700]);
	_obj.buttonBorderColorHover = createColor(_obj.buttonBGColorHover[700]);

	//SECONDARY
	_obj.homeSecondarybuttonBGColor = createColor(secondaryButtonBackgroundColor || '#FFF');
	_obj.homeSecondarybuttonBGColorHover = createColor(secondaryButtonHoverBackgroundColor || _obj.homeSecondarybuttonBGColor[700]);
	_obj.homeSecondarybuttonBorderColor = createColor(secondaryButtonBorderColor || _obj.primaryPaletteColor[500]);
	_obj.homeSecondarybuttonBorderColorHover = createColor(secondaryButtonHoverBorderColor || _obj.primaryPaletteColor[700]);

	//rest
	_obj.defaultBorderColor = createColor('#E6E6E6');
	_obj.correctAnswer = _obj.indicationColor;
	_obj.loginBackground = createColor('#F5F5F5');
	_obj.softBorderColor = createColor('#EFEFEF');
	_obj.subHeaderColor = createColor('#8A8A8A');
	_obj.homePageBlockPrimaryBackgroundColor = createColor(homePageBlockPrimaryBackgroundColor) || obj.primaryWorkform;
	_obj.homePageBlockSecondaryBackgroundColor = createColor(homePageBlockSecondaryBackgroundColor) || obj.secondaryWorkform;

	_obj.contentWidthXL = '1600px';
	_obj.contentWidthL = '1250px';
	_obj.contentWidthM = '1050px';
	_obj.contentWidthS = '700px';
	_obj.contentWidthXS = '340px';
	//breakpoints
	_obj.smartphoneBreakpoint = '@media only screen and (min-width : 375px)';
	_obj.tabletPortraitBreakpoint = '@media only screen and (min-width : 768px)';
	_obj.tabletLandscapeBreakpoint = '@media only screen and (min-width : 1024px)';
	_obj.laptopBreakpoint = '@media only screen and (min-width : 1280px)';
	_obj.desktopBreakpoint = '@media only screen and (min-width : 1400px)';
	_obj.desktopXLBreakpoint = '@media only screen and (min-width : 2100px)';

	//margins and paddings
	_obj.mainMenuListItemPaddingTop = '25px';
	_obj.mainMenuListItemPaddingBottom = '25px';
	_obj.defaultPadding = '25px';

	//fonts
	_obj.defaultTitleFont = "'Montserrat', sans-serif;";
	_obj.secondaryTitleFont = "'Montserrat', sans-serif";
	_obj.workformQuestionFont = "'Montserrat', sans-serif";
	_obj.defaultAnswerFont = "'Montserrat', sans-serif";
	_obj.defaultContentFont = "'Montserrat', sans-serif";

	_obj.workformTitleFont = {
		fontFamily: _obj.defaultContentFont,
		fontSize: '4rem',
		lineHeight: '130%',
		letterSpacing: 0,
		fontWeight: 500,
	};

	//heights
	_obj.defaultMobileMenuHeight = '64px';
	_obj.defaultDesktopMenuHeight = '100px';

	_obj.defaultWrapper = {
		maxWidth: _obj.contentWidthM,
		position: 'relative',
		margin: '0 auto',
		width: '100%',
		paddingLeft: '25px',
		paddingRight: '25px',
	};
	_obj.extraSmallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXS,
	};
	_obj.smallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthS,
	};
	_obj.largeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthL,
	};
	_obj.extraLargeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXL,
	};

	_obj.footerLogoStyle = {
		width: '10rem',
		height: '3rem',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		position: 'absolute',
		right: '10rem',
		bottom: '3rem',
	};

	_obj.registrationHeading = {
		large: {
			textAlign: 'center',
			width: '100%',
			maxWidth: '600px',
			fontSize: '40px',
			lineHeight: '130%',
			margin: '0 auto 1em',
		},
		small: {
			fontSize: '28px',
			letterSpacing: '.2rem',
		},
	};

	_obj.listStyle = {
		'& ol, & ul': {
			padding: 0,
			counterReset: 'li-index',
			listStyle: 'none',
		},
		'& li': {
			position: 'relative',
			paddingLeft: '2.75rem',
		},
		'& li::before': {
			content: "''",
			position: 'absolute',
			top: 0,
			left: 0,
		},
		'& ul li::before': {
			top: '12px',
			width: '8px',
			height: '8px',
			background: 'currentColor',
			borderRadius: '50%',
		},
		'& ol li::before': {
			counterIncrement: 'li-index',
			content: 'counter(li-index)',
		},
	};

	_obj.breakpoints = createBreakpoints({});

	const realTheme = _theme(_obj);

	return realTheme;
};

const _theme = (props) =>
	createMuiTheme({
		typography: {
			htmlFontsize: 10,
			fontFamily: [
				props.defaultContentFont,
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Montserrat',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		palette: {
			primary: props.primaryPaletteColor,
			secondary: props.secondaryPaletteColor,
			success: {
				light: green[200],
				main: green[400],
				dark: green[600],
				contrastText: '#ffffff',
			},
			info: {
				light: blue[300],
				main: blue[400],
				dark: blue[500],
				contrastText: '#ffffff',
			},
			warning: {
				light: amber[400],
				main: amber[600],
				dark: amber[700],
				contrastText: '#ffffff',
			},
		},
		manakin: createTheme({
			accountBackgroundImage: accountBackgroundImage,
			correctAnswer: props.correctAnswer,
			defaultActiveColor: green,
			defaultAnswerFont: props.defaultAnswerFont,
			defaultBackgroundColor: props.defaultBackgroundColor,
			defaultBorderColor: props.defaultBorderColor,
			defaultBorderHoverColor: props.defaultBorderHoverColor,
			defaultBoxBGColor: props.defaultBoxBGColor,
			defaultBoxColor: props.defaultBoxColor,
			defaultButtonBorderColor: props.buttonBorderColor,
			defaultButtonRadius: props.buttonRadius,
			defaultContentColor: props.defaultContentColor,
			defaultContentFont: props.defaultContentFont,
			defaultContentHover: props.defaultContentHover,
			defaultDesktopMenuHeight: props.defaultDesktopMenuHeight,
			defaultElementBGColor: props.defaultElementBGColor,
			defaultMobileMenuHeight: props.defaultMobileMenuHeight,
			defaultPadding: props.defaultPadding,
			defaultTitleColor: props.defaultTitleColor,
			defaultTitleFont: props.defaultTitleFont,
			defaultWorkformColor: props.defaultWorkformColor,
			defaultWrapper: props.defaultWrapper,
			desktopBreakpoint: props.desktopBreakpoint,
			desktopXLBreakpoint: props.desktopXLBreakpoint,
			extraLargeWrapper: props.extraLargeWrapper,
			extraSmallWrapper: props.extraSmallWrapper,
			footerLogo: footerLogo || logo,
			homePageBlockPrimaryBackgroundColor: props.homePageBlockPrimaryBackgroundColor,
			homePageBlockSecondaryBackgroundColor: props.homePageBlockSecondaryBackgroundColor,
			indicationColor: props.indicationColor,
			laptopBreakpoint: props.laptopBreakpoint,
			largeWrapper: props.largeWrapper,
			layoutButtonStyleSelected: {
				backgroundColor: props.primaryPaletteColor[500],
				border: 0,
				color: white[500],
			},
			LessonButtonColor: props.LessonButtonColor,
			linkColor: props.linkColor,
			loginBackground: props.loginBackground,
			loginBackgroundImage: loginBackgroundImage,
			loginVisual: loginVisual,
			logo: logo,
			mainMenuBackground: props.mainMenuBackground,
			mainMenuColor: props.mainMenuColor,
			mainMenuListItemPaddingBottom: props.mainMenuListItemPaddingBottom,
			mainMenuListItemPaddingTop: props.mainMenuListItemPaddingTop,
			mainMenuSecondaryColor: props.mainMenuSecondaryColor,
			navigationColor: props.navigationColor,
			pdfIcon: pdfIcon,
			primaryBoxColor: props.primaryBoxColor,
			primaryColor: props.primaryColor,
			primaryElementBGColor: props.primaryElementBGColor,
			primaryPaletteColor: props.primaryPaletteColor,
			primaryTypeColor: props.primaryTypeColor,
			primaryWorkformColor: props.primaryWorkformColor,
			secondaryBackgroundColor: props.secondaryBackgroundColor,
			secondaryBoxColor: props.secondaryBoxColor,
			secondaryColor: props.secondaryColor,
			secondaryElementBGColor: props.secondaryElementBGColor,
			secondaryNavigationColor: props.secondaryNavigationColor,
			secondaryTitleFont: props.secondaryTitleFont,
			secondaryTypeColor: props.secondaryTypeColor,
			secondaryWorkformColor: props.secondaryWorkformColor,
			smallWrapper: props.smallWrapper,
			smartphoneBreakpoint: props.smartphoneBreakpoint,
			softBorderColor: props.softBorderColor,
			subHeaderColor: props.subHeaderColor,
			tabletLandscapeBreakpoint: props.tabletLandscapeBreakpoint,
			tabletPortraitBreakpoint: props.tabletPortraitBreakpoint,
			tertiaryColor: props.tertiaryColor,
			typeColor: props.typeColor,
			underline: {
				width: '50px',
				height: '2px',
				backgroundColor: props.defaultContentColor[500],
				position: 'absolute',
				bottom: 0,
				left: '50%',
				transform: 'translate(-50%, 2.5rem)',
			},
			workformTitleFont: props.workformTitleFont,
		}),
		overrides: {
			AccordionFaq: {
				panelRoot: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
			},
			AppAccountWrapper: {
				root: {
					background: 'none',
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppBar: {
				dropdownInput: {
					fontSize: '1.4rem',
					letterSpacing: '.3rem',
					fontFamily: props.secondaryTitleFont,
					fontWeight: 'bold',
				},
				programsDropdownRoot: {
					[props.breakpoints.down('sm')]: {
						left: '25px',
						right: '25px',
						width: 'auto',
						marginTop: '120px',
						transform: 'none',
					},
				},
				menuButtonLabel: {
					fontSize: '2rem',
					fontWeight: 500,
					marginLeft: '22px',
				},
				buttonRoot: {
					marginLeft: '1rem',
				},
				backButtonRoot: {
					color: props.defaultContentColor[500],
				},
			},
			appBestTimes: {
				user: {
					backgroundColor: props.primaryColor[500],
				},
				body: {
					color: white[500],
				},
				currentUser: {
					backgroundColor: props.primaryPaletteColor[500],
					'& $body': {
						color: white[500],
					},
					'& $time': {
						color: white[500],
					},
				},
			},
			AppBottomButtons: {
				closeButtonRedo: {
					borderRadius: 0,
				},
			},
			AppBox: {
				footerLogo: {
					...props.footerLogoStyle,
					right: '12rem',
					bottom: '-39px',
				},
			},
			AppBoxCard: {
				boxRoot: {
					borderRadius: props.defaultButtonRadius,
				},
				withOverflow: {
					borderRadius: props.defaultButtonRadius,
				},
				backgroundImage: {
					transform: 'scale(1.1)',
				},
				percentage: {
					fontWeight: 800,
				},
				inner: {
					padding: '2.25rem',
					[props.breakpoints.up('md')]: {
						height: '40rem',
						padding: '0 6rem',
						'&:hover': {
							'& $backgroundImage': {
								transform: 'translateX(2%) scale(1.1)',
							},
							'& $overflow': {
								transform: 'scale(1) translate(2%, -20px)',
							},
							'& $boxDescription': {
								visibility: 'visible',
								transform: 'none',
								opacity: 1,
							},
							'& $boxSubtitle': {
								display: 'none',
							},
							'& $boxName': {
								opacity: 0,
								visibility: 'hidden',
								transform: 'translateY(-32px)',
							},
							'& $boxCardLabel': {
								transform: 'none',
							},
						},
					},
				},
				innerDone: {
					[props.breakpoints.up('md')]: {
						height: '30rem',
					},
				},
				boxName: {
					fontWeight: '600',
					letterSpacing: 0,
					transition: 'all .2s ease',
					fontSize: '3rem',
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						maxWidth: '50%',
					},
				},
				boxDescription: {
					position: 'absolute',
					marginTop: 0,
					visiblity: 'hidden',
					opacity: 0,
					transform: 'translateY(32px)',
					transition: 'all .2s ease',
				},
				boxCardText: {
					[props.breakpoints.up('md')]: {
						fontSize: '1.4rem',
					},
				},
				listView: {
					'& $boxName': {
						letterSpacing: 0,
						[props.breakpoints.up('md')]: {
							letterSpacing: 0,
						},
					},
					'&:hover': {
						'& $boxName': {
							opacity: 1,
							transform: 'translateY(0)',
							visibility: 'visible',
						},
					},
				},
				boxRootOverflow: {},
				listViewRoot: {
					'&$boxRootOverflow': {
						marginTop: 0,
					},
					'& $withOverflow': {
						overflow: 'hidden',
					},
					'& $overflow': {
						height: '100%',
						transform: 'scale(1.1)',
					},
					'& $withOverflow:hover': {
						'& $backgroundImage': {
							transform: 'translateX(2%) scale(1.1)',
						},
					},
				},
			},
			AppBoxes: {
				smallWrapper: {
					backgroundColor: '#fafafa',
				},
			},
			AppBoxHeader: {
				root: {
					backgroundColor: props.primaryBackgroundColor ? props.dashboardBackgroundColor[100] : 'transparant',
					[props.breakpoints.up('md')]: {
						paddingBottom: '20rem',
					},
				},
				heading: {
					color: props.primaryBackgroundColor ? props.boxBGColor['contrastText'] : props.primaryFontColor[500],
					'&::after': {
						backgroundColor: props.indicationColor[500],
					},
				},
				body: {
					color: props.primaryBackgroundColor ? props.boxBGColor['contrastText'] : props.defaultContentColor[500],
				},
			},
			AppBoxLayout: {
				wrapper: {
					[props.breakpoints.up('md')]: {
						paddingBottom: '4rem',
					},
				},
				root: {
					position: 'relative',
					paddingBottom: '10rem',
					[props.breakpoints.up('md')]: {
						// marginTop: "-28.5rem",
						paddingBottom: '14rem',
					},
				},
				footerLogo: {
					...props.footerLogoStyle,
					// backgroundImage: "none",
					right: '12rem',
				},
				listView: {
					backgroundColor: '#fafafa',
					paddingTop: '3rem',
					paddingBottom: '0',
				},
			},
			AppCalendarButton: {
				root: {
					borderRadius: props.defaultButtonRadius,
				},
			},
			AppContentHeader: {
				title: {
					letterSpacing: '0rem',
				},
			},
			AppDashboardHeader: {
				root: {
					backgroundColor: props.dashboardBackgroundColor[100],
					'& $contentColor': {
						color: props.dashboardContentColor[500],
					},
					'& $heading': {
						color: props.dashboardTitleColor[500],
					},
				},
				content: {
					[props.breakpoints.up('md')]: {
						marginBottom: '4.3rem',
					},
				},
				heading: {
					fontFamily: props.defaultContentFont,
				},
			},
			AppDownloadButton: {
				wrapper: {
					maxWidth: '1020px',
				},
			},
			AppElementCard: {
				contentColor: {},
				root: {
					borderRadius: props.defaultButtonRadius,
				},
				name: {
					letterSpacing: 0,
					fontWeight: 700,
					[props.breakpoints.up('md')]: {
						fontSize: '2.2rem',
						letterSpacing: 0,
						lineHeight: '3rem',
					},
				},
				type: {
					marginBottom: '1rem',
					fontSize: '1.5rem',
					fontWeight: '500',
				},
				body: {},
				bodytitle: {},
				useTextOverlay: {
					'& $content': {
						top: '65px',
					},
				},
				bookCheckContent: {
					'& $body': {
						margin: 0,
					},
					'& $bodytitle': {},
				},
				button: {
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
				playIconContainer: {
					width: '150px',
					height: '150px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
				playIcon: {
					width: '70%',
					height: '70%',
				},
				time: {
					[props.breakpoints.up('md')]: {
						top: '4.8rem',
						left: '4rem',
					},
				},
			},
			AppElementOverlay: {
				title: {
					fontFamily: props.secondaryTitleFont,
					fontWeight: 'bold',
				},
				closeButton: {
					backgroundColor: 'black',
					color: 'white',
					border: 0,
					'&:hover': {
						backgroundColor: 'black',
						color: 'white',
					},
				},
			},
			AppElementsRoot: {
				grid: {
					maxWidth: '1230px',
					marginTop: '-13rem',
				},
			},
			AppFactcardReferral: {
				// root: {
				// 	borderRadius: "0",
				// 	overflow: "hidden",
				// 	maxWidth: "1200px",
				// 	margin: "0 auto",
				// },
				title: {
					color: white[500],
					fontSize: '2.4rem',
					marginBottom: '.4rem',
				},
				body: {
					color: white[500],
				},
				multi: {
					'& $title': {
						marginBottom: '.4rem',
					},
					'& $body': {
						marginBottom: '3rem',
					},
				},
				button: {
					backgroundColor: 'transparent',
					border: '2px solid white',
					minHeight: '3rem',
					padding: '1.8rem!important',
					color: 'white',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
			AppFactcardsCard: {
				root: {
					borderRadius: props.defaultButtonRadius,
				},
				bookCheckContent: {
					'& $body': {
						margin: 0,
					},
				},
				button: {
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
				bookCheck: {
					backgroundColor: props.primaryElementBGColor[500],
					'& $bodytitle': {
						color: props.primaryElementBGColor['contrastText'],
					},
					'& $body': {
						color: props.primaryElementBGColor['contrastText'],
					},
				},
			},
			AppFirstPage: {
				wrapper: {
					maxWidth: props.contentWidthM,
				},
				titleRoot: {
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
					[props.breakpoints.up('lg')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
				},
			},
			AppLayoutButtons: {
				// label: {
				// 	color: props.tertiaryColor[500],
				// 	textTransform: "uppercase",
				// 	fontFamily: props.groldBold,
				// 	letterSpacing: "3px",
				// },
				tabsRoot: {
					marginBottom: '4rem',
				},
				// tabSelected: {
				// 	backgroundColor: black[500],
				// },
			},
			AppLesson: {
				largeWrapper: {
					maxWidth: '1230px',
					[props.breakpoints.up('md')]: {
						maxWidth: '1230px',
					},
				},
				normalMargin: {
					margin: '3rem 0',
					[props.breakpoints.up('md')]: {
						margin: '8rem 0',
					},
				},
			},
			AppLessonBottom: {
				root: {
					backgroundColor: white[500],
					borderTop: `1px solid ${props.defaultBorderColor[500]}`,
				},
				button: {
					margin: '0 1rem',
					minWidth: '300px',
				},
				backToBoxButton: {
					display: 'none',
				},
				redoQuestionButton: {
					display: 'flex',
				},
				nextQuestionButton: {
					display: 'flex',
				},
			},
			AppLessonIntroduction: {
				content: {
					color: props.defaultTitleColor[500],
					fontSize: '2rem',
					lineHeight: '3.6rem',
					fontWeight: '500',
					'&:first-letter': {
						fontSize: '16rem',
						lineHeight: '15.3rem',
						margin: '0 6rem 0 0',
					},
				},
			},
			AppLogin: {
				root: {
					display: 'flex',
					flexDirection: 'column',
					[props.breakpoints.up('lg')]: {
						marginTop: '9rem',
					},
				},
				registrationLink: {
					order: 3,
					marginTop: '3rem',
				},
				button: {
					fontSize: '1.8rem',
					backgroundColor: props.homeSecondarybuttonBGColor[500],
					color: props.homeSecondarybuttonBGColor['contrastText'],
					border: `2px solid ${props.homeSecondarybuttonBorderColor[500]}`,
					padding: '18px',
					'&:hover': {
						backgroundColor: props.homeSecondarybuttonBGColorHover[500],
						color: props.homeSecondarybuttonBGColorHover['contrastText'],
						border: `2px solid ${props.homeSecondarybuttonBorderColorHover[500]}`,
					},
				},
			},
			AppLoginDemoButton: {
				loginDemoButton: {
					borderRadius: props.homepageButtonRadius,
					color: props.homeSecondarybuttonBGColor['contrastText'],
					borderColor: props.homeSecondarybuttonBGColor['contrastText'],
				},
			},
			appLoginHeader: {
				root: {
					minHeight: 0,
					[props.breakpoints.up('lg')]: {
						minHeight: '800px',
					},
				},
				visualTitle: {
					bottom: 'auto',
					top: '50%',
					left: '50%',
					transform: 'translate(calc(-50% - 481px), calc(-50% + 230px))',
				},
				content: {
					backgroundPosition: 'left center',
				},
				logo: {
					height: '41px',
					width: '199px',
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
				},
				headerTitle: {
					fontFamily: props.defaultTitleFont,
					fontSize: '0rem',
					fontWeight: '500',
					textAlign: 'center',
					width: '100%',
					height: '70px',
					backgroundImage: `url(${logo})`,
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
				},
				footerLogo: {
					...props.footerLogoStyle,
				},
			},
			AppLogout: {
				root: {
					backgroundColor: 'white',
				},
			},
			AppMenu: {
				link: {
					color: white[500],
				},
			},
			AppOrderQuestion: {
				isCorrect: {
					backgroundColor: props.secondaryColor[500],
				},
			},
			appPointsPopup: {
				heading: {
					paddingRight: '30px',
				},
				button: {
					backgroundColor: props.primaryColor[500],
					color: white[500],
					'&:hover': {
						backgroundColor: 'black',
					},
				},
				nextCategory: {
					color: props.defaultContentColor[500],
				},
				points: {
					backgroundColor: props.secondaryColor[500],
					borderRadius: '100%',
				},
				// pointsBody: {
				// 	color: props.primaryPaletteColor.contrastText,
				// },
			},
			// AppPointsInfo: {
			// 	pointsBody: {
			// 		color: props.primaryPaletteColor.contrastText,
			// 	},
			// 	pointsTitle: {
			// 		color: props.primaryPaletteColor.contrastText,
			// 	},
			// },
			AppProfilePicture: {
				editPicture: {
					color: white[500],
				},
			},
			AppRadioGroup: {
				formControlLabel: {
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
					},
				},
			},
			AppRegister: {
				buttonPrimary: {
					backgroundColor: props.homeButtonBGColor[500],
					color: props.homeButtonBGColor['contrastText'],
					border: `2px solid ${props.homeButtonBorderColor[500]}`,
					'&:hover': {
						backgroundColor: props.homeButtonBGColorHover[500],
						color: props.homeButtonBGColorHover['contrastText'],
						borderColor: props.homeButtonBorderColorHover[500],
					},
				},
			},
			AppRegistrationAuthData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationPersonalData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolClassData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationScreen: {
				root: {
					minHeight: '100vh',
					backgroundColor: white[500],
				},
				heading: {
					textAlign: 'center',
				},
			},
			AppRegistrationSuccess: {
				buttonRoot: {
					backgroundColor: props.homeButtonBGColor[500],
					color: props.homeButtonBGColor['contrastText'],
					border: `2px solid ${props.homeButtonBorderColor[500]}`,
					'&:hover': {
						backgroundColor: props.homeButtonBGColorHover[500],
						color: props.homeButtonBGColorHover['contrastText'],
						borderColor: props.homeButtonBorderColorHover[500],
					},
				},
				root: {
					backgroundColor: props.primaryColor[500],
				},
				content: {
					margin: '0 0 0px',
				},
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
				paragraph: {
					color: white[500],
				},
				smallParagraph: {
					color: white[500],
				},
			},
			AppResumeButton: {
				root: {
					borderRadius: props.defaultButtonRadius,
					backgroundColor: props.dashboardResumeButton[500],
					overflow: 'hidden',
					'&:hover': {
						'& $body1': {
							color: props.dashboardResumeButton['contrastText'],
						},
						'& $longArrow': {
							'& .arrow': {
								backgroundColor: props.indicationColor[500],
							},
						},
					},
					'& $title': {
						fontWeight: 700,
					},
					'& body1': {
						fontWeight: 700,
					},
				},
				longArrow: {
					'& .arrow': {
						backgroundColor: props.indicationColor[500],
					},
				},
				body1: {
					color: props.dashboardResumeButton['contrastText'],
				},
			},
			appRoadmap: {
				wrapper: {
					[props.breakpoints.up('lg')]: {
						padding: '0 9rem',
					},
				},
			},
			appRoadmapStep: {
				header: {
					textTransform: 'none',
					fontSize: '30px',
					lineHeight: '28px',
					paddingBottom: '2.4rem',
				},
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
				},
			},
			AppSearchBar: {
				closeButton: {
					backgroundColor: 'black',
					color: 'white',
					border: 0,
					'&:hover': {
						backgroundColor: 'black',
						color: 'white',
					},
				},
			},
			AppSearchInput: {
				root: {
					margin: 0,
				},
			},
			AppSliderInput: {
				sliderLabel: {
					fontSize: '16px',
				},
			},
			AppSortSlider: {
				questionContent: {
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
					},
				},
			},
			AppStatementQuestion: {
				button: {
					minHeight: '3rem',
					padding: '36px 20px',
					borderRadius: props.buttonRadius,
					[props.breakpoints.up('md')]: {
						minWidth: '16rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
			AppStatisticsBox: {
				statistics: {
					[props.breakpoints.up('md')]: {
						border: `1px solid ${props.primaryPaletteColor[100]}`,
					},
				},
				specificStatisticItem: {
					border: `1px solid ${props.primaryPaletteColor[100]}`,
					[props.breakpoints.up('md')]: {
						border: 0,
					},
				},
			},
			AppTeacherHeader: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				heading: {
					color: props.defaultTitleColor[500],
				},
				body: {
					color: props.defaultTitleColor[500],
				},
			},
			AppTeacherProgram: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppTeacherReport: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				content: {
					'& $heading': {
						color: props.defaultTitleColor[500],
					},
				},
			},
			AppVideoPlayer: {
				// activeIcon: {
				// 	color: props.correctAnswer[500],
				// },
				playButton: {
					'& $icon': {
						backgroundColor: props.secondaryColor[500],
					},
				},
			},
			AppWorkformLayout: {
				header: {
					fontWeight: '500',
					marginBottom: '7rem',
					fontSize: '4rem',
					marginTop: '1.8rem',
					[props.breakpoints.down('xs')]: {
						fontSize: '3rem',
					},
				},
				// wrapper: {
				// 	[props.breakpoints.up("md")]: {
				// 		maxWidth: "100%",
				// 	},
				// },
				// subHeader: {
				// 	fontFamily: props.secondaryTitleFont,
				// 	color: black[500],
				// 	fontSize: "1.4rem",
				// 	padding: "0 0 .75rem",
				// 	[props.breakpoints.up("md")]: {
				// 		fontSize: "2rem",
				// 	},
				// 	"&::after": {
				// 		backgroundColor: props.primaryPaletteColor[500],
				// 	},
				// },
				secondaryColors: {
					'& $contentColor': {
						color: white[500],
					},
					'& $button': {
						color: white[500],
					},
				},
				// container: {
				// 	maxWidth: "100%",
				// },
			},
			AuthenticationCmsAuthenticator: {
				root: {
					backgroundColor: white[500],
				},
			},
			AuthenticationConfirmReset: {
				root: {
					background: white[500],
				},
			},
			AuthenticationLoginForm: {
				submit: {
					padding: '2rem',
					marginTop: '3.4rem',
					backgroundColor: props.homeButtonBGColor[500],
					color: props.homeButtonBGColor['contrastText'],
					border: `2px solid ${props.homeButtonBorderColor[500]}`,
					'&:hover': {
						backgroundColor: props.homeButtonBGColorHover[500],
						color: props.homeButtonBGColorHover['contrastText'],
						borderColor: props.homeButtonBorderColorHover[500],
					},
				},
			},
			AuthenticationResetPassword: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			ClaimLicense: {
				root: {
					width: '100%',
					height: '100%',
				},
			},
			HeaderFaq: {
				heading: {
					letterSpacing: '0rem',
				},
			},
			ManakinLoginForm: {
				root: {
					backgroundColor: yellow[500],
				},
			},
			MuiBadge: {
				colorSecondary: {
					backgroundColor: 'red',
				},
			},
			MuiButton: {
				root: {
					zIndex: 1,
					height: 'auto',
					padding: '3rem',
					borderRadius: props.buttonRadius,
				},
				contained: {
					boxShadow: 'none',
				},
				containedPrimary: {
					backgroundColor: props.buttonBGColor[500],
					color: props.buttonBGColor['contrastText'],
					'&:hover': {
						backgroundColor: props.buttonBGColorHover[500],
						color: props.buttonBGColorHover['contrastText'],
					},
				},
				label: {
					fontFamily: props.defaultContentFont,
					position: 'relative',
					textTransform: 'none',
					zIndex: 1,
					fontSize: '1.8rem',
					lineHeight: '2rem',
					textDecoration: 'none',
				},
				outlinedPrimary: {
					border: `2px solid ${props.primaryPaletteColor[500]}`,
					padding: '18px',
					'&:hover': {
						border: `2px solid ${props.primaryPaletteColor[500]}`,
					},
				},
			},
			MuiChip: {
				label: {
					fontSize: '14px',
				},
			},
			MuiDialog: {
				paper: {
					margin: '18px',
					[props.breakpoints.up('md')]: {
						padding: '4.4rem 5rem 1rem',
					},
				},
				paperFullWidth: {
					minHeight: '53rem',
					borderRadius: 0,
				},
			},
			MuiDialogActions: {
				root: {
					justifyContent: 'center',
					flexDirection: 'column',
					[props.breakpoints.up('md')]: {
						margin: 0,
					},
				},
				action: {
					width: '100%',
					padding: '2rem',
				},
			},
			MuiDialogContent: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
				},
			},
			MuiDialogTitle: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
					'&>h2': {
						marginBottom: '4rem',
					},
				},
			},
			MuiDrawer: {
				paper: {
					padding: '3rem',
					fontFamily: "'Heebo', sans-serif;",
					fontSize: '1.8rem',
					color: props.mainMenuColor,
					background: props.mainMenuBackground[500],
					[props.breakpoints.up('md')]: {
						padding: '1.8rem 4rem',
						fontSize: '3rem',
					},
				},
			},
			MuiFab: {
				primary: {
					backgroundColor: black[500],
					color: white[500],
					'&:hover': {
						backgroundColor: black[400],
					},
				},
			},
			MuiFormControl: {
				root: {
					width: '100%',
				},
			},
			MuiFormControlLabel: {
				root: {
					marginRight: '20px',
					width: '40%',
					'@media (min-width: 1400px)': {
						width: 'auto',
						marginRight: '50px',
					},
				},
			},
			MuiFormGroup: {
				root: {
					width: '100%',
					flexDirection: 'row',
				},
			},
			MuiFormHelperText: {
				root: {
					fontSize: '1.25rem',
				},
			},
			MuiFormLabel: {
				root: {
					fontFamily: props.defaultContentFont,
					fontSize: '1.8rem',
					color: props.primaryPaletteColor[500],
				},
			},
			MuiInput: {
				input: {
					padding: '14px 0',
					fontSize: '1.8rem',
					lineHeight: '3rem',
				},
			},
			MuiInputBase: {
				inputMultiline: {
					minHeight: '14rem',
				},
			},
			MuiMenuItem: {
				root: {
					fontSize: '1.6rem',
				},
			},
			MuiPickersDay: {
				daySelected: {
					'& >*': {
						margin: 0,
						marginTop: '16px',
					},
				},
				day: {
					'& >*': {
						margin: 0,
						marginTop: '16px',
					},
				},
			},
			MuiPickersSlideTransition: {
				transitionContainer: {
					'& >*': {
						top: '-6px',
					},
				},
			},
			MuiSelect: {
				icon: {
					top: 'calc(50% - 10px)',
				},
			},
			MuiStepConnector: {
				root: {
					display: 'none',
				},
			},
			MuiStepIcon: {
				root: {
					transform: 'scale(1.7)',
					margin: '0 10px',
				},
				text: {
					fontFamily: props.defaultContentFont,
					fontSize: '1.2rem',
				},
			},
			MuiStepLabel: {
				iconContainer: {
					padding: '0 20px 0 0',
				},
				labelContainer: {
					display: 'none',
				},
			},
			MuiStepper: {
				root: {
					backgroundColor: 'transparent',
					padding: '0',
					margin: '30px 0',
				},
			},
			MuiSvgIcon: {
				root: {
					width: '2rem',
					height: '2rem',
				},
			},
			MuiTableCell: {
				root: {
					padding: '4px 16px 4px 16px',
				},
				head: {
					border: 0,
					fontSize: '1.2rem',
					color: props.defaultContentColor[500],
					fontFamily: props.defaultTitleFont,
					lineHeight: '2rem',
					letterSpacing: '.4rem',
				},
				body: {
					paddingTop: '1.5rem',
					paddingBottom: '1.5rem',
				},
			},
			MuiTablePagination: {
				caption: {
					fontSize: '1.6rem',
				},
				select: {
					fontSize: '1.6rem',
					lineHeight: '1.6rem',
					paddingRight: 32,
				},
			},
			MuiTypography: {
				root: {
					fontSize: '2rem',
				},
				h1: {
					fontFamily: props.defaultTitleFont,
					fontSize: '4rem',
					fontWeight: '600',
					lineHeight: '5.2rem',
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '8rem',
						lineHeight: '10rem',
					},
				},
				h2: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2.85rem',
					lineHeight: '3.85rem',
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					fontWeight: '600',
					[props.breakpoints.up('md')]: {
						fontSize: '7rem',
						lineHeight: '8rem',
					},
				},
				h3: {
					fontFamily: props.defaultTitleFont,
					fontSize: '3rem',
					lineHeight: '4rem',
					color: props.defaultTitleColor[500],
					fontWeight: '600',
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '3.2rem',
						lineHeight: '5rem',
					},
				},
				h4: {
					color: props.defaultTitleColor[500],
					fontFamily: props.defaultTitleFont,
					fontWeight: '600',
					fontSize: '2.4rem',
					lineHeight: '5rem',
					marginBottom: '3.6rem',
					[props.breakpoints.up('md')]: {
						lineHeight: '5.2rem',
						marginBottom: '4.6rem',
					},
				},
				h5: {
					color: props.defaultTitleColor[500],
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.4rem',
					fontWeight: '600',
					lineHeight: '3.2rem',
					marginBottom: '1.3rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '0.5rem',
					},
				},
				h6: {
					color: props.defaultTitleColor[500],
					fontFamily: props.defaultTitleFont,
					fontSize: '1.8rem',
					fontWeight: '600',
					lineHeight: '2rem',
					marginBottom: '1.3rem',
					letterSpacing: '.2rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '1.6rem',
					},
				},
				subtitle1: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '2rem',
					lineHeight: '4rem',
					color: props.defaultContentColor[500],
					marginBottom: '3rem',
				},
				subtitle2: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.4rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					marginBottom: '1.4rem',
					fontWeight: '500',
					[props.breakpoints.up('md')]: {
						marginBottom: '4rem',
					},
				},
				body1: {
					fontSize: '1.8rem',
					color: props.defaultContentColor[500],
					fontWeight: 400,
					lineHeight: '3.6rem',
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '3rem',
					},
				},
				body2: {
					fontSmoothing: 'auto',
					color: props.defaultContentColor[500],
					fontFamily: props.secondaryTitleFont,
					fontWeight: 400,
					fontSize: '2rem',
					lineHeight: '3.8rem',
					marginBottom: '2rem',
				},
				gutterBottom: {
					marginBottom: '3em',
				},
			},
			PrivateValueLabel: {
				label: {
					fontSize: '14px',
				},
			},
			SingleDot: {
				HotspotBlockText: {
					lineHeight: '28px',
				},
			},
		},
	});

export default theme;
