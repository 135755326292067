import React from 'react';
import CoreAppMenu from '@manakin/app-core/AppMenu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {getAppUser} from '@manakin/authentication/selectors';
import {LoginTypes} from "@manakin/app-core/constants";
import { useGetConfig } from '@manakin/hooks';

const styles = (theme) => ({
    root: {},
    link: {
        color: theme.manakin.mainMenuColor[500],
        textDecoration: 'none',
        fontSize: '1.8rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem',
        },
    },
    activeLink: {
        color: theme.manakin.mainMenuColor[500],
        borderBottom: '.2rem solid',
    },
    listItemRoot: {
        paddingTop: theme.manakin.mainMenuListItemPaddingTop,
        paddingBottom: theme.manakin.mainMenuListItemPaddingBottom,
        paddingLeft: '0',
        paddingRight: '0',
    },
    listItemTextRoot: {
        padding: '0',
    },
});

const AppMenu = props => {
    const {classes} = props;
    const {t} = useTranslation();
    const appUser = useSelector((state) => getAppUser(state));
    const isSSO = appUser.loginType === LoginTypes.ThirdParty;

    return (
        <CoreAppMenu
            renderGeneral={() => (
                <List>
                    <ListItem classes={{root: classes.listItemRoot}}>
                        <NavLink
                            className={classes.link}
                            activeClassName={classes.activeLink}
                            to={"/"}
                            exact
                        >
                            <ListItemText
                                classes={{
                                    primary: classes.link,
                                    root: classes.listItemTextRoot,
                                }}
                                primary={t("app.menu.programs")}
                            />
                        </NavLink>
                    </ListItem>
                    <ListItem classes={{root: classes.listItemRoot}}>
                        <NavLink
                            className={classes.link}
                            activeClassName={classes.activeLink}
                            to={"/factcards"}
                            exact
                        >
                            <ListItemText
                                classes={{
                                    primary: classes.link,
                                    root: classes.listItemTextRoot,
                                }}
                                primary={t("app.menu.fact-cards")}
                            />
                        </NavLink>
                    </ListItem>
                    {!isSSO && (
                        <ListItem classes={{root: classes.listItemRoot}}>
                            <NavLink
                                className={classes.link}
                                activeClassName={classes.activeLink}
                                to={"/faq"}
                                exact
                            >
                                <ListItemText
                                    classes={{
                                        primary: classes.link,
                                        root: classes.listItemTextRoot,
                                    }}
                                    primary={t("app.menu.faq")}
                                />
                            </NavLink>
                        </ListItem>
                    )}
                </List>
            )}
        />
    );
};

export default withStyles(styles, {name: 'AppMenu'})(AppMenu);